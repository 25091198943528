<template>
  <div
    class="CodeDisplayCard"
    @click.stop>
    <div
      v-if="code"
      class="Code">
      <div class="Label">
        {{ $t('code') }}
      </div>
      <div class="code">
        {{ code.code }}
      </div>
      <div v-if="code.explanation">
        <div class="Label descriptionLabel">
          {{ $t('description') }}
        </div>
        <div>{{ code.explanation }}</div>
      </div>
    </div>
    <div
      v-else
      class="Code">
      {{ $t('nocode') }}
    </div>
  </div>
</template>

<translations>
  code: Code
  code_no: Kode
  nocode: No code
  nocode_no: Ingen kode
  description: Description
  description_no: Beskrivelse
</translations>
<script>
export default {
    // components: { NameDebug, NavigationWithDots, Carousel },
    inject: ['module'],
    props: {
        codeId: {
            type: String,
            required: true
        }
    },
    computed: {
        code() {
            let code = null;
            // if (this.name.codes) {
            code = this.$store.getters['moduleSystemName/getCodeById'](this.codeId);
            // }
            return code;
        }
    },
    methods: {}
};
</script>

<style scoped lang="scss">
.CodeDisplayCard {
    // flex: 1;
    background-color: mix(black, white, 10%);
    border-radius: 0.5em;
    // margin-top: 1em;

    display: flex;
    flex-direction: column;
}

.code {
    font-weight: 600;
}
.Label {
    color: rgba(black, 0.5);
    margin-bottom: 0.5em;
}

.descriptionLabel {
    margin-top: 2em;
}

.footer {
    // padding: 0 2em;
    height: $names-codeCardFooterHeight;
    border-top: 1px solid mix(black, white, 15%);
    display: flex;
    align-items: center;
}
.Code {
    padding: 2em;
    flex: 1;
}

.spacer {
    flex: 1;
}

.avatar {
    border-left: 1px solid mix(black, white, 15%);
    align-self: stretch;
    width: $names-codeCardFooterHeight;
    display: flex;
    justify-content: center;
    align-items: center;
}
.avatarIcon {
    $size: 2.5em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: gray;
}

.editButton {
    // $size: 2em;
    // width: $size;
    // height: $size;
    // border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 2em;
    padding-right: 0.5em;
    align-self: stretch;
}
.editButtonCircle {
    $size: 2em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: red;

    display: flex;
    justify-content: center;
    align-items: center;
}
.editButtonIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
}

.likeButton {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    padding-left: 1em;

    color: rgba(black, 0.5);
}
.likeButtonIcon {
    $size: 1.2em;
    width: $size;
    height: $size;
    fill: rgba(black, 0.3);
    margin-right: 0.5em;
}
</style>
