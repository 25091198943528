<template>
  <div
    class="NameWithCodesCard"
    @click.stop>
    <div class="nameSection">
      <div class="Label">
        {{ $t('name') }}
      </div>
      <div class="name">
        {{ name.value }}
      </div>
    </div>
    <div
      v-if="showDebug"
      class="debugSection">
      <NameDebug :name-id="nameId" />
    </div>

    <Carousel
      class="foobar"
      :items="carouselItems"
      :index="carouselItemIndex"
      :transition-duration="0.5"
      :show-placeholders="true">
      <template
        slot="item"
        slot-scope="props">
        <CodeCard v-bind="props" />
      </template>
    </Carousel>

    <NavigationWithDots
      :class="{ hidden: carouselItems.length === 1 }"
      :index="carouselItemIndex"
      :length="carouselItems.length"
      @click="index => (carouselItemIndex = index)" />
  </div>
</template>

<translations>
  name: Name
  name_no: Navn
</translations>

<script>
import NavigationWithDots from '@/modules/games-shared/components/NavigationWithDots';
import Carousel from '@/modules/games-shared/components/Carousel';

import NameDebug from './NameDebug';
import CodeCard from './CodeCard';

export default {
    components: { NameDebug, NavigationWithDots, Carousel, CodeCard },
    inject: ['module'],
    props: {
        nameId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showDebug: false,
            carouselItemIndex: 0
        };
    },
    computed: {
        name() {
            return this.$store.getters['moduleSystemName/getNameById'](this.nameId);
        },
        carouselItems() {
            const items = (this.name.codes || []).map(codeId => {
                return {
                    codeId
                };
            });
            return items;
        }
    },
    methods: {}
};
</script>

<style scoped lang="scss">
.NameWithCodesCard {
    color: mix(black, white, 80%);
    flex-direction: column;
    display: flex;
}
.nameSection {
    background-color: white;
    border-radius: 0.5em;
}
.name {
    font-size: 130%;
    font-weight: 600;
}

.Label {
    color: rgba(black, 0.5);
    margin-bottom: 0.5em;
}

.descriptionLabel {
    margin-top: 2em;
}
.nameSection {
    padding: 2em;
}

.Carousel {
    flex: 1;
    margin-left: -2em;
    margin-right: -2em;

    margin-top: 1em;
}
.CodeCard {
    height: 100%;
}

.NavigationWithDots {
    margin-top: 0.5em;
    &.hidden {
        opacity: 0;
    }
}

.debugSection {
    background-color: white;
    padding: 1em;
}
</style>
