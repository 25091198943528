<template>
  <div class="NameDebug">
    NameDebug
    <br>
    <div class="reps">
      <div
        v-for="(rep, i) of reps"
        :key="i"
        class="Rep"
        :class="{ current: rep.current }"
        @click="setRepLevel(i)">
        <span class="Rep__label">{{ rep.label }}</span>
      </div>
    </div>
    <div>
      <span
        class="option"
        :class="{ selected: currentRep.isDue }"
        @click="setDue(true)">due</span>
      <span
        class="option"
        :class="{ selected: !currentRep.isDue }"
        @click="setDue(false)">not due</span>
    </div>
  </div>
</template>

<script>
export default {
    inject: ['module'],
    props: {
        nameId: {
            type: String,
            required: true
        }
    },
    computed: {
        name() {
            return this.$store.getters['moduleSystemName/getNameById'](this.nameId); // || { value: 'missing' }
        },
        userState() {
            return this.module.state.namesUserstate[this.nameId] || { repLevel: 0 };
        },
        reps() {
            const reps = [
                { label: 'XX' },
                { label: '1D' },
                { label: '7D' },
                { label: '1M' },
                { label: '3M' },
                { label: '6M' }
            ];
            reps[this.userState.repLevel].current = true;
            reps[this.userState.repLevel].isDue = this.userState.due || false;
            return reps;
        },
        currentRep() {
            return this.reps[this.userState.repLevel];
        }
    },
    methods: {
        setDue(flag) {
            const repLevel = this.userState.repLevel;
            if (repLevel === 0) {
                return;
            }
            this.module.dispatch('dbgSetNameUserstate', {
                nameId: this.nameId,
                data: {
                    repLevel,
                    due: flag
                }
            });
        },
        setRepLevel(level) {
            this.module.dispatch('dbgSetNameUserstate', {
                nameId: this.nameId,
                data: {
                    repLevel: level
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
.reps {
    display: flex;
}
.Rep {
    display: inline-block;
    // width: 2em;
    // $size: 2em;
    // width: $size;
    // height: $size;
    // border-radius: 50%;
    // background-color: gray;
    // color: white;
    // margin: 4px;
    // text-align: center;
    // line-height: $size;
    &:not(:first-child) {
        margin-left: 1em;
    }

    &.current {
        border-bottom: 2px solid black;
    }
}
.Rep__label {
    // font-size: 80%;
}

.option.selected {
    border-bottom: 2px solid black;
}
</style>
