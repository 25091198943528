<template>
  <FlipContainer
    class="CodeCard"
    :flipped="flipped">
    <CodeDisplayCard
      v-if="codeId"
      slot="front"
      :code-id="codeId"
      @request-edit="flip" />
    <CodeCreateCard
      v-else
      slot="front"
      @request-create="flip" />

    <CodeEditCard
      slot="back"
      :code-id="codeId"
      @request-unflip="unflip" />
  </FlipContainer>
</template>

<script>
import FlipContainer from '@/modules/games-shared/components/FlipContainer';

import CodeDisplayCard from './CodeDisplayCard';
import CodeEditCard from './CodeEditCard';
import CodeCreateCard from './CodeCreateCard';

export default {
    components: { FlipContainer, CodeDisplayCard, CodeEditCard, CodeCreateCard },
    inject: ['module'],
    props: {
        codeId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            flipped: false
        };
    },
    methods: {
        flip() {
            this.flipped = true;
        },
        unflip() {
            this.flipped = false;
        }
    }
};
</script>

<style scoped lang="scss">
.CodeCard {
}
</style>
