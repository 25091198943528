<template>
  <div
    class="CodeCreateCard"
    @click.stop="foo">
    <div class="body">
      <div class="text">
        Lag ditt eget morsomme husketips
      </div>
      <svgicon
        class="icon"
        icon="pen-memorytip" />
    </div>

    <div class="footer">
      <div
        class="createButton --global-clickable"
        @click="$emit('request-create')">
        <div class="createButtonCircle">
          <svgicon
            class="createButtonIcon"
            icon="plus2" />
        </div>
        <span>Lag husketips</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    inject: ['module'],
    props: {
        codeId: {
            type: String
        }
    },
    data() {
        return {
            code: 'Edit the code...'
        };
    },
    computed: {
        // code () {
        //   let code = null
        //   // if (this.name.codes) {
        //   code = this.$store.getters['moduleSystemName/getCodeById'](this.codeId)
        //   // }
        //   return code
        // }
    },
    methods: {
        foo() {},

        cancel() {
            //
            this.$emit('request-unflip');
        },
        done() {
            this.$emit('request-unflip');
        }
    }
};
</script>

<style scoped lang="scss">
.CodeCreateCard {
    background-color: mix(black, white, 10%);
    border-radius: 0.5em;

    display: flex;
    flex-direction: column;
}
.body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em 2em;
    flex: 1;
}
.text {
    color: rgba(black, 0.8);
    max-width: 12em;
    text-align: center;
    line-height: 1.5em;
    // font-weight: 600;
    // font-size: 120%;
}
.icon {
    $size: 4em;
    width: $size;
    height: $size;
    fill: rgba(black, 0.8);
    margin-top: 1em;
}

.footer {
    height: $names-codeCardFooterHeight;
    // padding: 0 2em;
    border-top: 1px solid mix(black, white, 15%);
    display: flex;
    justify-content: flex-start;
}
.createButton {
    display: flex;
    align-items: center;
    align-self: stretch;
    padding-left: 2em;
    color: rgba(black, 0.5);
}
.createButtonCircle {
    $size: 2em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5em;
}
.createButtonIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
}
</style>
