<template>
  <div
    class="CodeEditCard"
    @click.stop="foo">
    <div class="Code">
      <div class="Label">
        Code
      </div>
      <div class="code">
        <textarea v-model="code" />
      </div>
      <div class="Label descriptionLabel">
        Description
      </div>
      <div>Add explanation</div>
    </div>

    <div class="footer">
      <div
        class="cancelButton --global-clickable"
        @click="cancel()">
        Cancel
      </div>
      <div
        class="doneButton --global-clickable"
        @click="done()">
        <span>Done</span>
        <div class="doneButtonCircle">
          <svgicon
            class="doneButtonIcon"
            icon="arrow-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    inject: ['module'],
    props: {
        codeId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            code: 'Edit the code...'
        };
    },
    computed: {
        // code () {
        //   let code = null
        //   // if (this.name.codes) {
        //   code = this.$store.getters['moduleSystemName/getCodeById'](this.codeId)
        //   // }
        //   return code
        // }
    },
    methods: {
        foo() {},

        cancel() {
            //
            this.$emit('request-unflip');
        },
        done() {
            this.$emit('request-unflip');
        }
    }
};
</script>

<style scoped lang="scss">
.CodeEditCard {
    // flex: 1;
    background-color: mix(black, white, 10%);
    border-radius: 0.5em;
    // margin-top: 1em;

    // height: 4em;
    display: flex;
    flex-direction: column;
}

.code {
    font-weight: 600;
}
.Label {
    color: rgba(black, 0.5);
    margin-bottom: 0.5em;
}

.descriptionLabel {
    margin-top: 2em;
}

.footer {
    height: $names-codeCardFooterHeight;
    // padding: 0 2em;
    border-top: 1px solid mix(black, white, 15%);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Code {
    padding: 2em;
    flex: 1;
}

.cancelButton {
    padding-left: 2em;
    align-self: stretch;
    display: flex;
    align-items: center;
}

.doneButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 2em;
    align-self: stretch;
}
.doneButtonCircle {
    $size: 2em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5em;
}
.doneButtonIcon {
    $size: 1em;
    width: $size;
    height: $size;
    fill: white;
}
</style>
